export const LangDefaults = {
  CountryLanguage: 'English',
  localCurrency: 'USD',
  localCountryLang: 'us-en',
  countryCode: 'us',
  country: 'USA',
};

export type countryType = {
  countryName: string;
  countryLang: string;
  countryLanguage?: string;
  country: string;
  currency: string;
};

export const UseLang = (
  countryList: countryType[],
  test: boolean,
  countryTest: string
): [string, string, string, string, string] => {
  let country = '';
  let currency = '';
  let lang = LangDefaults.localCountryLang; // us-en langage by default
  let userLocale = LangDefaults.localCountryLang; // us-en langage by default
  //
  let ddoCountry = LangDefaults.countryCode;
  const ddoRetrieved = new URLSearchParams(window.location.search).get(
    'ddo_country'
  );
  if (ddoRetrieved) {
    ddoCountry = ddoRetrieved;
  } else if (test === true) {
    ddoCountry = countryTest;
  } else {
    //setDdoCountry(window?.digitalData?.user?.location?.country.toLowerCase());
    ddoCountry = navigator.language;
    if (navigator.language.length === 6) {
      ddoCountry = navigator.language.slice(0, 2);
    } else {
      ddoCountry = navigator.language;
    }
  }
  if (ddoCountry === null || ddoCountry === undefined) {
    ddoCountry = LangDefaults.countryCode; // Default in case it does not exists
  }
  // Look in currencies if country Lang exists
  const corrspondingCountryFetched = countryList.find((object) => {
    const lang = object.countryLang as string;
    if (lang.slice(3, 5) === ddoCountry) {
      console.log(`Detected country code: ${ddoCountry}`);
      userLocale = object.countryLang;
      return object;
    } else {
      return undefined;
    }
  });
  if (corrspondingCountryFetched) {
    const fetchedLang = corrspondingCountryFetched
      ? corrspondingCountryFetched.countryLang
      : LangDefaults.localCountryLang;
    userLocale = fetchedLang;
    if (fetchedLang) {
      lang = fetchedLang;
      country = corrspondingCountryFetched.country;
      currency = corrspondingCountryFetched.currency;
    }
  } else {
    country = LangDefaults.country;
    currency = LangDefaults.localCurrency;
  }

  return [lang, userLocale, country, currency, ddoCountry];
};
