import { useEffect } from 'react';
import { executeNoBodyRequest } from 'src/common/fetchData';
import { environment, getVariable, getEnv } from 'src/common/useEnv';

export interface emailProperties {
  emailFrom?: string;
  emailTo: string;
  emailCC?: string;
  emailSubject?: string;
  emailText: string;
  html?: string;
  attachments?: [
    {
      filename: string;
      path: string;
    }
  ];
}

// Note: variablesProperties must match the COS object structure stored in the remote service for this to
// work properly.
export interface variablesProperties {
  variables: {
    emailTo: string;
    emailSubject: string;
    hostingService: string;
  };
}

/**
 *
 */
export const useReadCOS = async (
  feedback: string,
  setResponse: React.Dispatch<
    React.SetStateAction<{
      variables: {
        emailTo: string;
        emailSubject: string;
        hostingService: string;
      };
    }>
  >,
  emailJson: string
) => {
  const [env] = await getEnv();
  let server = '';
  switch (env) {
    case environment.production:
      server = 'https://emailer.17f48735.public.multi-containers.ibm.com';
      break;
    case environment.preview:
      server =
        'https://emailer-test-development.968d96a4.public.multi-containers.ibm.com';
      break;
    case environment.stage:
      server =
        'https://emailer-test-development.968d96a4.public.multi-containers.ibm.com';
      break;
  }
  useEffect(() => {
    const headers: any = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    console.log(`env ${env} and ${server}`);
    if (feedback) {
      executeNoBodyRequest(
        `${server}/store/target/${env}/${emailJson}`,
        'GET',
        headers
      ).then((emailResponse) => {
        if (emailResponse.status === 200) {
          const content = JSON.stringify(emailResponse.message);
          const variables: variablesProperties = JSON.parse(content);
          setResponse(variables);
        } else {
          const result: variablesProperties = {
            variables: {
              emailTo: '',
              emailSubject: '',
              hostingService: '',
            },
          };
          setResponse(result);
        }
      });
    }
  }, [feedback, env, server, emailJson, setResponse]);
};

/**
 *
 */
export const readBucket = async (
  emailJson: string
): Promise<[Record<string, string>[]]> => {
  const env = getVariable();
  let server = '';
  switch (env) {
    case environment.production:
      server = 'https://emailer.17f48735.public.multi-containers.ibm.com';
      break;
    case environment.preview:
      //server = 'http://localhost:3001';
      server =
        'https://emailer-preprod.968d96a4.public.multi-containers.ibm.com';
      break;
    case environment.stage:
      // server = 'http://localhost:3001';
      server =
        'https://emailer-test-development.968d96a4.public.multi-containers.ibm.com';
      break;
  }
  console.log(`env ${env} and ${server} and ${emailJson}`);
  let response = [{}];

  if (emailJson.length !== 0) {
    const headers: any = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    await executeNoBodyRequest(
      `${server}/store/bucket/${env}/${emailJson}`,
      'GET',
      headers
    ).then((bucketResponse) => {
      if (bucketResponse.status === 200) {
        const content = JSON.stringify(bucketResponse.message);
        const variables = JSON.parse(content);
        response = variables;
      } else {
        response = [
          {
            message: `${JSON.stringify(env)}/${emailJson} not found`,
          },
        ];
      }
    });
  } else {
    response = [
      {
        message: `${JSON.stringify(env)} JSON file not provided.`,
      },
    ];
  }

  return [response];
};
