import { ReactElement, useEffect } from 'react';
import { Modal } from 'carbon-components-react';
import { segmentTracking as segmentsTracking } from '../../lib/tracker';
import BusinessPartner from 'src/components/BusinessPartner/BusinessPartner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import picture from '../../assets/form_image.png';

const pageTitle = 'IBM Blueworks Live Demo';
const object = 'URX Form Modal';

export interface FormModalProps {
  bpName: string;
  logo: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormModal = ({
  bpName,
  logo,
  drawerOpen,
  setDrawerOpen,
}: FormModalProps): ReactElement => {
  const { t /* i18n */ } = useTranslation();

  useEffect(() => {
    const replaceBpField = () => {
      const bpField = document.getElementById('Q_BPNAME') as HTMLInputElement;
      if (bpName && bpField) {
        console.log(
          `Came here: `,
          bpField,
          ' label',
          t('message.preferred_bp')
        );
        //update label text
        const bpLabel = bpField.labels?.length
          ? bpField.labels[0]
          : new HTMLInputElement();
        bpLabel.innerHTML = t('message.preferred_bp');

        //replace the bp field by the bp name
        const bpText = document.createElement('p') as HTMLInputElement;
        bpText.innerText = bpName;
        bpText.style.fontSize = '1rem';
        bpText.style.fontWeight = 'bold';
        bpText.style.lineHeight = '2.5';
        bpField.parentNode?.replaceChild(bpText, bpField);
      }
    };
    setDrawerOpen(drawerOpen);
    replaceBpField();
  }, [bpName, drawerOpen, setDrawerOpen, t]);

  return (
    <div>
      <div className="URX_FORM">
        <Modal
          passiveModal={true}
          open={drawerOpen}
          size={'lg'}
          onRequestClose={() => {
            segmentsTracking('CTA Clicked', {
              pageTitle,
              object,
              resultValue: 'CTA clicked',
              CTA: 'Form modal closed on the corner',
              location: 'URX Form',
              action: `Form modal closed`,
              field: '',
            });
            setDrawerOpen(false);
          }}
        >
          <div className="bx--row mar3">
            <div className="bx--col-lg-16 bx--col-md-16 bx--col-sm-16">
              {bpName ? (
                <BusinessPartner bpName={bpName} logo={logo} urxForm={true} />
              ) : null}
            </div>
          </div>
          <div className="bx--row mar4 mar6">
            <div className="bx--col-lg-8 bx--col-md-7 bx--col-sm-3">
              <img src={picture} alt="Not found" id="img1"></img>
            </div>
            <div className="bx--col-lg-8 bx--col-md-7 bx--col-sm-6">
              <div id="urx-form-container">
                <div no-toc="true" id="urx-form"></div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FormModal;
