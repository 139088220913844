import { useEffect, useState } from 'react';

/**
 * hook to get params URL cookie value
 *
 * @returns {string} cookie value
 */
export const useCookiesURL = (): string => {
  const [cookieURL, setCookieValue] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lnk = urlParams.get('lnk');
    const mpid = urlParams.get('mpid');

    let newCookieValue = '';

    if (lnk === 'dss2023') {
      newCookieValue +=
        'Lead generated in outbound cadence. Please transfer ownership of CI to DSS.|';
    }

    if (mpid) {
      newCookieValue += `Hyperscaler:${mpid}|`;
    }
    newCookieValue += ' |DIAC CI| Referenced URL:' + window.location.href
    setCookieValue(newCookieValue);
  }, []);

  return cookieURL;
};
