import { useEffect, useState } from 'react';
import { getEnv } from './useEnv';
import Cookies from 'js-cookie';

/**
 * Hook to get the business partner details from the BPPR API only. BPPR API has been switched ON and COS has been switch OFF.
 * Business Partner details will be fetched via the BPPR API - V2 henceforth which is being mapped with param ?bpid
 * To invoke the old logic from COS, please use the param ?bppr
 * v1 - useBPName() returns the business partner details from internal cloud storage(COS)
 * v2 - useBPName(UT30WidgetCode, true) returns business partner details from BPPR with sticky feature
 *
 * @param widgetName - set the UT30 widget code to get the business partner details from bppr and make bpid sticky
 * @param bppr - set to true to enable bppr api call
 * @returns {
 *  bpName - business partner name
 *  logo - business partner logo,
 *  companyId - business partner id or ceid
 *  desc - business partner descrption
 *  mssp - this is mssp flag which is either true or false, preset by the business partners
 *  param - string
 * } business partner details
 */

export type BPType = {
  companyId: string;
  logo: string;
  bpName: string;
  desc: string;
  mssp: string;
  params: string;
};

export const useBPName = (widgetName = '', bppr = false) => {
  const [bpName, setBpName] = useState('');
  const [logo, setLogo] = useState('');
  const [desc, setDesc] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [bpList, setBpList] = useState<BPType[]>([]);
  const [mssp, setMssp] = useState('');
  const [environment, setEnvironment] = useState('');
  const [bpprId, setBpprId] = useState('');
  const [params, setParams] = useState('');
  const programName = 'Co-Branded Unique URL'; // programe name for bppr
  const bpprHostProd = 'https://www.ibm.com/easytools/cpds/bpprserver/bppr/api'; // bppr host url for prod
  const bpprHostUat =
    'https://wwwstage.ibm.com/easytools/cpds/uat/bpprserver/bppr/api'; // bppr host url for uat
  const bpprTokenUrlProd =
    'https://us-east.functions.appdomain.cloud/api/v1/web/9bc40b51-cfe1-4ece-8e9a-3c29ee99c928/default/api-proxy-bppr-provider.json';
  const bpprTokenUrlUAT =
    'https://us-east.functions.appdomain.cloud/api/v1/web/b518bfd3-62a8-4cb3-a614-0bcdcd34c040/default/api-proxy-bppr-provider-dev.json';

  useEffect(() => {
    getEnv().then((res) => {
      console.log(`Environment in BP:`, res[0]);
      setEnvironment(res[0]);
      return res[0];
    });
    const getBPListUrl = (bppr: boolean) => {
      if (bppr) {
        return environment === 'production'
          ? 'https://cos-dsh-qradar-bp.s3.eu-de.cloud-object-storage.appdomain.cloud/bpList_Logo.json'
          : 'https://cos-dsh-qradar-bp.s3.eu-de.cloud-object-storage.appdomain.cloud/bpList_Logo_test.json';
      }
      return 'https://cos-dsh-qradar-bp.s3.eu-de.cloud-object-storage.appdomain.cloud/bpList.json';
    };

    /* Note: 
      BPPR has been switch ON.
      Business Partner details will be fetched via the BPPR API - V2 henceforth which is being mapped with param ?bpid
      To invoke the old logic from COS, please use the param ?bppr
     */

    const idRetvdFmddo = window.digitalData?.page?.attributes?.bpid;
    console.log(`idRetrieved from DDO ${idRetvdFmddo}`);

    const idRetrieved =
      window.digitalData?.page?.attributes?.bpid ||
      new URLSearchParams(window.location.search).get('bpid') ||
      sessionStorage.getItem(widgetName);

    // Param for stickiness
    const pathRetrieved =
      window.location.search.toString() ||
      sessionStorage.getItem(widgetName + 'Path') ||
      '';

    // BPPR V1
    if (idRetrieved && !bppr) {
      sessionStorage.setItem(widgetName, idRetrieved); // to make the bpid sticky
      setCompanyId(idRetrieved);
      fetch(getBPListUrl(bppr ? true : false), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setBpList(bppr ? data.businesspartners : data);
        })
        .catch((error) => {
          console.log(`Fetching error for BPPR: ${error}`);
        });
    }

    // BPPR V2
    if (idRetrieved && bppr) {
      sessionStorage.setItem(widgetName, idRetrieved); // to make the bpid sticky
      setCompanyId(idRetrieved);
      setBpprId(idRetrieved);
    }

    if (idRetrieved) {
      sessionStorage.setItem(widgetName + 'Path', pathRetrieved); // sticky the path
      setParams(pathRetrieved);
    }
  }, [environment, params, bppr, widgetName]);

  useEffect(() => {
    const getBpprHost = () => {
      return environment === 'production' ? bpprHostProd : bpprHostUat;
    };

    const getTokenUrl = () => {
      return environment === 'production' ? bpprTokenUrlProd : bpprTokenUrlUAT;
    };

    const getToken = async () => {
      return await fetch(getTokenUrl()).then((res) => res.json());
    };

    const getBPPRUrl = (ceid: string | null, widgetCode: string) => {
      return `${getBpprHost()}/getRegistrationDetails?ProgramName=${programName}&CEID=${ceid}&widgetCode=${widgetCode}`;
    };

    const getBPPRLogoUrl = (fileName: string, email?: string) => {
      return `${getBpprHost()}/getlogoFile/${programName}/${email}/${fileName}`;
    };

    const getBPPRLogo = (
      fileName: string,
      headers: Headers,
      email?: string
    ) => {
      fetch(getBPPRLogoUrl(fileName, email), {
        method: 'GET',
        headers: headers,
      })
        .then((res) => res.url)
        .then((logo) => {
          setLogo(logo ? logo : '');
        })
        .catch((error) => {
          console.log(`Fetching error BPPR logo: ${error}`);
        });
    };

    const setBPPRDetails = (
      ceid: string | null,
      token: string,
      widgetCode: string
    ) => {
      const bearerToken = `Bearer ${token}`;
      const headers = new Headers();
      headers.append('Authorization', bearerToken);
      headers.append('token', token);
      headers.append('Access-Control-Allow-Origin', '*');
      fetch(getBPPRUrl(ceid, widgetCode), {
        method: 'GET',
        headers: headers,
      })
        .then((res) => (res.status === 401 ? res.text() : res.json()))
        .then(async (data) => {
          if (
            data?.message === 'user Registration not found' ||
            data?.message === 'Requested widget code is not present'
          ) {
            console.error(
              `${data.message} with bpid ${ceid} and widgetcode ${widgetCode}`
            );
          } else if (data !== 'Authorization token is Expired') {
            setBpName(data.companyName);
            setDesc(data.companyDescription);
            if (data.ibmUniqueid && data.filenames) {
              getBPPRLogo(data.filenames[0], headers, data.ibmUniqueid);
            } else {
              console.error(
                `Logo could not be fetched as either ibmUniqueid or logo filename is not present`
              );
            }
          } else {
            generateBPPR(bpprId); // Refreshing token scenario
          }
        })
        .catch((error) => {
          console.log(`Fetching error for BPPR: ${error}`);
        });
    };

    const generateBPPR = (idRetrievedBppr: string) => {
      getToken()
        .then((data) => {
          if (data.access_token) {
            const now = new Date();
            now.setSeconds(now.getSeconds() + data.expires_in);
            Cookies.set('bppr_token', data.access_token, {
              expires: now,
              domain: 'www.ibm.com',
            });
          }
          setBPPRDetails(idRetrievedBppr, data.access_token, widgetName);
        })
        .catch((error) => {
          console.log(`Error while generating token: ${error}`);
        });
    };

    const bp = bpList.find((bp) => bp.companyId === companyId);
    if (bp && Object.keys(bp).length) {
      setBpName(bp.bpName);
      // Find BP Logo
      const bpImg = bpList.find((bpImg: BPType) => bpImg.logo !== '');
      if (bpImg) {
        setLogo(bp.logo);
      }

      // Find BP Description
      const bpDesc = bpList.find((bpDesc: BPType) => bpDesc.desc !== '');
      if (bpDesc) {
        setDesc(bp.desc);
      }
      // Find BP MMSP=yes
      const bpMssp = bpList.find((bpMssp: BPType) => bpMssp.mssp === 'yes');
      if (bpMssp) {
        setMssp(bp.mssp);
      }
    }

    // To fetch & set the BP details via the BPPR API
    if (bpprId) {
      const tokenFromCookie = Cookies.get('bppr_token');
      if (tokenFromCookie) {
        setBPPRDetails(bpprId, tokenFromCookie, widgetName);
      } else generateBPPR(bpprId);
    }
  }, [bpList, companyId, bpprId, environment, widgetName]);

  return { bpName, logo, companyId, desc, mssp, params };
};
