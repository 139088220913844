/**
 *
 * @param environment
 * @param instanceId
 * @param formId
 * @param urxlang
 * @param bpid
 */
export const initUrxForm = (
  environment: string,
  instanceId: string,
  formId: string,
  urxlang: string,
  bpid?: string
) => {
  console.log(
    `Form parameters; environment=`,
    environment,
    'formId=',
    formId,
    'instanceId=',
    instanceId
  );

  if (
    (document.getElementById(instanceId) as HTMLDivElement).childNodes
      .length === 0
  ) {
    window.loadedUrxForm = false;
    window.urxEnvironment = environment;
    window.renderUrxWidget(
      instanceId,
      formId,
      urxlang,
      {
        column: 3,
        theme: 'light',
        singleStep: true,
        triggerManually: false,
      },
      cb,
      bpid ? bpid : ''
    );
  }
};

function cb(): boolean {
  console.log(`we load the form`);
  window.loadedUrxForm = true;
  return true;
}
