import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { DSH_Figma_Utils } from '../../common/figmaListener';
import { productTitle } from '../../lib/tracker';
import { getEnv } from '../../common/useEnv';
import { readBucket } from 'src/common/readCOS';
import { LangDefaults } from 'src/common/useLang';

const pageTitle = 'IBM Blueworks Live Demo';

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s1: true,
      text: 'click',
      iframeUrl: '',
      figmaKey: '',
      figmaToken: '',
    };
  }

  componentDidMount = () => {
    const track = 'track1';
    const localeCode = this.props.urxlang;
    console.log(` userLanguage`, this.props.urxlang, localeCode);
    getEnv().then((res) => {
      console.log(`****\n res:`, res);
      const bucket = res[1];
      this.setState({ figmaToken: res[2] });
      readBucket(bucket).then((response) => {
        const figmaTrackContent =
          response[0].track !== null ? response[0][track] : null;
        figmaTrackContent.map((obj) => {
          console.log(`Processing: `, obj.countryLang);
          if (obj.countryLang === localeCode) {
            console.log(`Found demo:`, obj.countryLang);
            this.setState({
              iframeUrl: obj.emailDemoUrl,
              figmaKey: obj.figmaKey,
            });
            return obj;
          }
          return '';
        });
        if (this.state.iframeUrl.length === 0) {
          figmaTrackContent.map((obj) => {
            console.log(`Processing default: `, LangDefaults.localCountryLang);
            if (obj.countryLang === LangDefaults.localCountryLang) {
              console.log(`Found demo:`, obj.countryLang);
              this.setState({
                iframeUrl: obj.emailDemoUrl,
                figmaKey: obj.figmaKey,
              });
              return obj;
            }
            return '';
          });
        }
        const clickCallbackFunc = (nodeID) => {
          console.log('==>nodeID:', nodeID.toString());
          if (nodeID.indexOf('I223:113') !== -1) {
            document.getElementById('cta-try-free')?.click();
          }
          if (nodeID.indexOf('I223:114') !== -1) {
            document.getElementById('cta-buy-now')?.click();
          }
        };
        DSH_Figma_Utils.init({
          figma_fileKey: this.state.figmaKey,
          figma_accessToken: this.state.figmaToken,
          metricsTracking: segmentTracking,
          clickCallback: clickCallbackFunc,
          product_title: productTitle,
          page_title: pageTitle,
        });
      });
    });
  };

  render() {
    const { runningMobile /* i18n */ } = this.props;
    return (
      <div>
        <div className="bx--grid" id="main-page">
          {/* <Content id="main-area">{t('MainPage.content')}</Content> */}
          <div className="bx--row">
            <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
              <div className="section">
                {this.props.bpName ? (
                  <>
                    <div className="bx--row figma_top">
                      <div className="bx--col"></div>
                    </div>
                  </>
                ) : (
                  <div className="bx--row"></div>
                )}
                <div className="bx--row">
                  <iframe
                    tabIndex={'-1'}
                    onLoad={() => {
                      window.parent.focus();
                    }}
                    className={
                      runningMobile ? 'figma_frame1_mobile' : 'figma_frame1'
                    }
                    title="Blueworks demo path 1"
                    id="figma_demo1"
                    src={this.state.iframeUrl}
                  ></iframe>
                </div>
                <div className="bx--row">
                  <div className="bx--col figma_bottom1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(MainPage);
