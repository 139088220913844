import { useState, useEffect } from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  // HeaderMenuItem,
  HeaderGlobalBar,
  //HeaderGlobalAction,
  SkipToContent,
} from 'carbon-components-react/es/components/UIShell';
import { ArrowRight16 } from '@carbon/icons-react';
import { Button, Loading, ButtonSet } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { useCookiesURL } from 'src/lib/UseCookiesUrl';
import Cookies from 'js-cookie';
import * as URX_FORM from '../../constants/urx-form';
import { initUrxForm } from 'src/common/loadUrxForm';
import { getEnv } from '../../common/useEnv';
import Helmet from 'react-helmet';
import { urxScriptLoader, urxScript } from 'src/common/globalDefinitions';
import Thanks from '../Thanks/Thanks';

const pageTitle = 'IBM Blueworks Demo';

const NavHeader = ({
  bpName,
  logo,
  companyId,
  desc,
  mssp,
  params,
  lang,
  urxlang,
  drawerOpen,
  setDrawerOpen,
  closeModal,
  setCloseModal,
  showImage,
  setShowImage,
  demo,
  setDemo,
}) => {
  const { t /* i18n */ } = useTranslation();
  const cookieURL = useCookiesURL();

  const [environment, setEnvironment] = useState('');

  const [loadIcon, setLoadIcon] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  window.onUrxFormSubmitSuccess = () => {
    setDrawerOpen(false);
    setCloseModal(true);
    setFormComplete(!formComplete);
  };

  useEffect(() => {
    getEnv().then((res) => {
      console.log(`Environment:`, res[0]);
      setEnvironment(res[0]);
      return res[0];
    });
  }, []);

  const setCookie = () => {
    const getBp = () => {
      if (bpName) return bpName;
      if (logo) return companyId;
      if (desc) return desc;
      if (mssp) return mssp;
      const bpField = document.querySelector('#Q_BPNAME');
      return bpField ? bpField.value : '';
    };
    const text = JSON.stringify({
      source: URX_FORM.FORM_ID,
      // One field to provide all values (stringify)
      dcq: {
        Q_NOTESDATA:
          cookieURL +
          ' Book a live demo -IBM Blueworks demo-' +
          ' | Referenced URL:' +
          window.location.href +
          ' | FormId:' +
          URX_FORM.FORM_ID +
          (getBp() ? `|Preferred BP:${getBp()}` : ''),
        Q_ASSET_NAME: 'IBM Blueworks demo', // ISC - Asset Name
      },
    });
    Cookies.set('urxdcq', text);
    console.log('cookie', Cookies.get('urxdcq'));
  };

  const buyNow = () => {
    let url =
      'https://www.ibm.com/marketplace/purchase/configuration/en/checkout?editionID=EID9OEQQ';
    segmentTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: 'Nav Header',
      resultValue: `Loaded: ${url}`,
      CTA: 'Buy now',
      location: 'Demo',
      action: 'Clicked',
      field: '',
    });
    setTimeout(async () => {
      window.open(url);
    }, 250);
  };
  const getProduct = () => {
    let url = 'https://www.ibm.com/products/blueworkslive';
    segmentTracking('CTA Clicked', {
      pageTitle: pageTitle,
      object: 'Nav Header',
      resultValue: `Loaded: ${url}`,
      CTA: 'Product page',
      location: 'Demo',
      action: 'Clicked',
      field: '',
    });
    setTimeout(async () => {
      window.open(url);
    }, 250);
  };

  return (
    <div className="Header">
      <Helmet>
        <script type="application/javascript" src={urxScriptLoader}></script>
        <script type="application/javascript" src={urxScript}></script>
      </Helmet>
      <div className="bx--row nam">
        <Header aria-label="Client Center NavBar" className="header">
          <SkipToContent />
          <HeaderName
            element={Link}
            to={params}
            prefix=""
            onClick={() => {
              getProduct();
            }}
          >
            <p className="type2">{'IBM ' + t('Page.title')}</p>
          </HeaderName>
          <HeaderNavigation aria-label="App-Navbar">
            {/* <HeaderMenuItem element={Link} to={`/page-link`}>{t('NavHeader.8')}</HeaderMenuItem> */}
          </HeaderNavigation>
          <HeaderGlobalBar>
            <ButtonSet className="Header__stack-button btn-set">
              <Button
                className="button-header2"
                kind="tertiary"
                id="cta-try-free"
                renderIcon={ArrowRight16}
                onClick={() => {
                  setLoadIcon(true);
                  initUrxForm(
                    environment,
                    URX_FORM.INSTANCE_ID,
                    URX_FORM.FORM_ID,
                    urxlang,
                    companyId
                  );
                  segmentTracking('CTA Clicked', {
                    pageTitle: pageTitle,
                    object: 'Nav Header',
                    resultValue: `Loaded: ${URX_FORM.FORM_ID}`,
                    CTA: 'Try for free',
                    location: 'Demo',
                    action: 'Clicked',
                    field: '',
                  });
                  setTimeout(
                    async () => {
                      setCookie();
                      setLoadIcon(false);
                      setDrawerOpen(true);
                      setShowImage(false);
                      setDemo(
                        'https://myibm.ibm.com/dashboard?partNum=QldMX1RSSUFM'
                      );
                    },
                    bpName ? 1000 : 10
                  );
                }}
              >
                {t('trial.title')}
              </Button>
              <Button
                id="cta-buy-now"
                renderIcon={ArrowRight16}
                onClick={() => {
                  buyNow();
                }}
              >
                {t('buyNow.title')}
              </Button>
            </ButtonSet>
          </HeaderGlobalBar>
        </Header>
        <div className="button-set">{loadIcon ? <Loading /> : null}</div>
      </div>
      {formComplete && (
        <Thanks bpName={bpName} demo={demo} executeOnContinue={true} />
      )}
    </div>
  );
};

export default NavHeader;
