import { executeNoBodyRequest } from '../common/fetchData';
/**
 * hook to get environment name
 * We can't use the staging setting unitil we know the MIP team enables
 * every single form used by the widgets from thh staging environment.
 * /*
 * @info: use
 * `$SME$ test`
 * for Company name to prevent creating official IBM CI
 *
 * @returns {string} environment
 */

export enum environment {
  production = 'production',
  stage = 'stage',
  preview = 'preview',
}

export interface varaibles {
  environment: string;
  bucket: string;
  figmaToken: string;
}

let vars = {} as varaibles;

let variable = environment.preview;
let bucket = '';

export const getEnv = async (): Promise<[string, string, string]> => {
  let env = environment.preview;
  let bucketFile = '';
  let token = '';
  const hostingService = '/environment';
  await executeNoBodyRequest(`${hostingService}`, 'GET').then((response) => {
    //console.log(`Obtained: `, response);
    if (response.status === 200) {
      vars = response.message as unknown as varaibles;
      bucketFile = vars.bucket;
      token = vars.figmaToken;
      if (vars.environment === 'production') {
        env = environment.production;
        variable = environment.production;
      } else if (vars.environment === 'stage') {
        env = environment.stage;
        variable = environment.stage;
      }
    }
    return [env, bucketFile, token];
  });
  return [env, bucketFile, token];
};

export const getVariable = () => {
  return variable;
};

export const getBucket = () => {
  return bucket;
};
